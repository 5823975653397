import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import Lottie, {
  AnimationConfig,
  AnimationEventCallback,
  AnimationItem
} from 'lottie-web/build/player/lottie_light'
import { AnimatedIconNames } from 'enums/animatedIconNames'
import { margin, MarginProps } from 'styled-system'
import { ICON_ANIMATION_CONFIG } from 'shared/enums/icon'

export interface AnimatedIconProps
  extends Pick<AnimationConfig, 'loop' | 'autoplay'>,
    MarginProps {
  name: AnimatedIconNames
  playOnClick?: boolean
  reverseOnEvenClick?: boolean
  /**
   * Stop the animation on last frame.
   */
  freezeOnLastFrame?: boolean
  size?: number
  /**
   * Takes a string to output a class. Related to emotion - It lets another component style it.
   */
  className?: string
}

type StyledAnimatedIconProps = Required<Pick<AnimatedIconProps, 'size'>>

/**
 * Use this component to render animated icons.
 *
 * We are using LottieFiles to animate icons. A Lottie is a JSON-based animation
 * file format that enables designers to ship animations on any platform as easily
 * as shipping static assets. They are small files that work on any device and can
 * scale up or down without pixelation.
 *
 * Lottie is a rather large dependency. We use use the lighter version of Lottie
 * to avoid bringing too much code into your final bundle.
 *
 * Main difference between lottie_light and lottie:
 * lottie_light has only the svg renderer and doesn't support expressions.
 * */

const AnimatedIcon = ({
  name,
  loop = false,
  autoplay = false,
  playOnClick = false,
  reverseOnEvenClick = false,
  freezeOnLastFrame = false,
  size = 32,
  className,
  ...other
}: AnimatedIconProps) => {
  const CUSTOM_CONFIG = ICON_ANIMATION_CONFIG?.[name]
  const SPEED = CUSTOM_CONFIG?.speed ?? 1
  const [animationIsReversed, setAnimationIsReversed] = useState<boolean>(false)
  const DIRECTION = SPEED * (animationIsReversed ? -1 : 1)
  const [animation, setAnimation] = useState<AnimationItem | undefined>(
    undefined
  )
  const containerRef = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    if (!animation) return

    if (reverseOnEvenClick) {
      animation.setSpeed(DIRECTION)
    }
    animation.play()
    setAnimationIsReversed(!animationIsReversed)
  }

  useEffect(() => {
    const container = containerRef.current
    if (!container || animation) return

    setAnimation(
      Lottie.loadAnimation({
        container,
        animationData: require(`assets/icons/animatedIcons/${name}.json`), // dynamically import json file
        renderer: 'svg', // "canvas", "html",
        loop,
        autoplay
      })
    )
  }, [animation, autoplay, loop, name])

  useEffect(() => {
    if (!animation || loop || !freezeOnLastFrame) return
    const enterFrameCallback: AnimationEventCallback = frameEvent => {
      const defaultLastFrame = frameEvent.totalTime - 1
      const customLastFrame = CUSTOM_CONFIG?.lastFrameToFreezeOn
      const lastFrame = customLastFrame ?? defaultLastFrame

      if (frameEvent.currentTime <= lastFrame) return

      animation.pause()
    }

    animation.addEventListener('enterFrame', enterFrameCallback)

    return () => animation.removeEventListener('enterFrame', enterFrameCallback)
  }, [CUSTOM_CONFIG?.lastFrameToFreezeOn, animation, freezeOnLastFrame, loop])

  return (
    <StyledAnimatedIcon
      size={size}
      onClick={playOnClick ? handleClick : undefined}
      className={className}
      ref={containerRef}
      {...other}
    />
  )
}

export default AnimatedIcon

const StyledAnimatedIcon = styled.div<StyledAnimatedIconProps>(
  ({ size }) => ({
    height: size,
    width: size
  }),
  margin
)
